@import '/src/assets/styles/variable.scss';

.rp-netbanking {
  border: 1px solid $grey-04;
  padding: 16px;
  padding-right: 0;
  border-radius: 4px;

  &__search {
    .ant-input-affix-wrapper {
      padding-left: 8px;
    }

    input {
      color: $grey-02;
      font-family: 'Neurial Grotesk Light', sans-serif;
    }
  }

  &__text {
    font-family: 'Neurial Grotesk', sans-serif;
    font-size: 15px;
    color: $grey-03;
    margin: 16px 0;
    text-transform: uppercase;
  }

  &__list {
    max-height: 220px;
    overflow-y: auto;

    &-item {
      width: 100%;
      display: flex;

      &__selector {
        width: 100%;
        min-height: 44px;
        border: none;
        padding: 10px 4px;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }

        &::before {
          display: none !important;
        }

        &:first-child {
          border: none;
        }

        &:hover,
        &:focus {
          background-color: $grey-06;
          border-radius: 8px;
        }

        .ant-radio-button-checked {
          background-color: $grey-06;
          border-radius: 8px;

          &:focus-within {
            box-shadow: none;
          }
        }

        &.ant-radio-button-wrapper-checked {
          .rp-netbanking__list-item__checked {
            display: flex;
          }
        }

        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
          border: none;

          &:focus-within {
            box-shadow: none !important;
          }
        }
      }

      &__label-container {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
      }

      &__logo {
        display: flex;
        width: 40px;
      }

      &__name {
        margin-left: 40px;
        height: 24px;
        font-family: 'Neurial Grotesk', sans-serif;
        font-size: 15px;
        line-height: 23px;
        color: $blue-03;
      }

      &__checked {
        display: none;
        position: absolute;
        right: 0;
        height: 24px;
        width: 24px;
      }
    }
  }
}
