@import '/src/assets/styles/variable.scss';

.rp-payment-completed {
  background-color: white;
  padding: 32px 16px 60px 16px;
  position: relative;

  &__logo {
    &-group {
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;
    }

    &-item {
      height: 30px;

      &.--client-merchant {
        display: flex;
        align-items: center;
        column-gap: 10px;

        img {
          width: 30px;
        }

        p {
          font-size: 9px;
        }
      }
    }
  }

  &__checked-icon {
    margin-bottom: 32px;
    display: flex;
    height: 80px;
    width: 80px;
  }

  &__main {
    &__title {
      text-align: center;
      font-family: 'Neurial Grotesk Light', sans-serif;
      font-size: 36px;
      line-height: 44px;
      letter-spacing: -0.75px;
      background: linear-gradient(141.98deg, #5138D8 6.99%, #B120B7 89.76%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }

    &__desc {
      margin-top: 8px;
      font-family: 'Neurial Grotesk', sans-serif;
      font-size: 15px;
      color: $blue-03;
    }

    &__details {
      margin-top: 80px;
      font-family: 'Neurial Grotesk Light', sans-serif;
      font-size: 18px;
      letter-spacing: -0.25px;
      color: #223B6F;

      &-container {
        padding-top: 19px;
        padding-bottom: 17px;
        border-bottom: 1px solid #B7D2E8;

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          padding-bottom: 0;
          border-bottom: none;
        }
      }

      &__label {
        font-family: 'Neurial Grotesk Bold', sans-serif;
      }
    }
  }

  &__download-receipt {
    margin-top: 48px;

    button {
      font-family: 'Neurial Grotesk', sans-serif;
      font-style: normal;
      font-size: 15px;
      line-height: 22px;
      letter-spacing: -0.25px;
      color: #223B6F;
    }

    &__btn-text {
      display: flex;
      column-gap: 15px;
      align-items: flex-end;
    }
  }

  .btn-done-container {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;

    button {
      height: 50px;
      background: linear-gradient(141.98deg, #5138D8 6.99%, #B120B7 89.76%);
      border-radius: 0;

      &:hover,
      &:focus {
        opacity: 0.9;
        background: linear-gradient(141.98deg, #5138D8 6.99%, #B120B7 89.76%);
        color: #FFF;

        .btn-gradient__content {
          background: unset;
          -webkit-background-clip: unset;
          -webkit-text-fill-color: unset;
          background-clip: unset;
        }
      }
    }
  }
}
