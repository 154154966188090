@import '/src/assets/styles/variable.scss';

.highlight-test-mode-container {
  z-index: 1112;
  position: absolute;
  width: 100%;

  .horizontal-line {
    background-color: $secondary-color;
    width: 100%;
    height: 10px;
    position: absolute;
    top: 0;
  }

  .content {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;

    .trapezoid {
      height: 42px;
      width: 200px;
      position: absolute;
      top: -18px;
      background-color: $secondary-color;
      clip-path: polygon(5% 97%, 95% 97%, 100% 41%, 0 40%);
      border-radius: 25px;
      text-align: center;
      padding-top: 20px;
      padding-left: 15px;
      color: #FFF;
      font-size: 12px;
      text-transform: uppercase;
    }
  }
}
