@import "/src/assets/styles/variable.scss";

.rp-input-container {
  position: relative;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  .custom-input__label {
    z-index: 4;
  }

  input {
    padding: 12px 16px !important;
    border: 1px solid #B7D2E8;
    border-radius: 4px !important;
    display: block;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    font-family: 'Neurial Grotesk Light', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: rgba(5, 8, 72, 0.8);

    &:hover {
      border-color: #B7D2E8;
    }

    &:not(.ant-input-status-error):focus {
      box-shadow: none;
      outline: none;
      position: relative;
      border-color: transparent;
      z-index: 1;
      height: calc(100% - 2px);
      width: calc(100% - 2px);
      padding: 12px 15px !important;

      &~.hover-style {
        opacity: 1;
      }

      ~.custom-input__label {
        z-index: 3;
        visibility: visible;
        opacity: 1;
        color: rgba(34, 59, 111, 0.8);
      }
    }

    &:not(:placeholder-shown) {
      border-color: $grey-03;
    }

    &:not(:placeholder-shown)~.custom-input__label {
      visibility: visible;
      z-index: 3;
      color: rgba(34, 59, 111, 0.8);
    }

    &.ant-input-disabled {
      background: $grey-07;
      opacity: 1;
      border: 1px solid $grey-06;
      box-sizing: border-box;
      color: $blue-03;

      &[disabled]:hover {
        border-color: $grey-06;
      }
    }

    &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
    &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
      border-color: $red-error;
      z-index: 3;

      &~.custom-input__label {
        color: $red-error;
      }
    }
  }

  .rp-input__prefix {
    position: absolute;
    top: 13px;
    font-family: 'Neurial Grotesk Light', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    z-index: 3;
    left: 20px;
    visibility: hidden;
  }

  &.--prefix {
    input {

      &:focus,
      &:not(:placeholder-shown) {
        padding-left: 50px !important;
      }

      &:not(:placeholder-shown)~.rp-input__prefix,
      &:focus~.rp-input__prefix {
        visibility: visible;
      }
    }
  }
}

.rp-input__eyes {
  position: absolute;
  right: 16px;
  top: 18px;
  z-index: 1;
  cursor: pointer;
}
