@import "/src/assets/styles/variable.scss";

.rp-date-picker-container {
  .ant-picker {
    height: 48px !important;
    padding: 0 16px !important;
    border-radius: 4px !important;
    border: 1px solid #B7D2E8;

    input {
      color: rgba(5, 8, 72, 0.8);
    }

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 4px !important;
      border: 1px solid transparent !important;
      background: $input-focus-gradient border-box;
      -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      visibility: hidden;
      height: 48px;
      top: -1px;
      left: -1px;
      right: -1px;
    }

    &.ant-picker-focused {
      box-shadow: none;
      border-color: transparent;

      &::before {
        visibility: visible;
      }

      ~.custom-input__label {
        visibility: visible;
        opacity: 1;
        color: rgba(34, 59, 111, 0.8);
        z-index: 3;
      }
    }

    &.ant-picker-status-error.ant-picker,
    &.ant-picker-status-error.ant-picker:not([disabled]):hover {
      border-color: $red-error !important;
      z-index: 3;

      &~.custom-input__label {
        color: $red-error;
      }

      &::before {
        visibility: hidden;
      }
    }
  }

  &.--has-value {
    .ant-picker {
      border: 1px solid $grey-03  !important;
    }

    &.ant-picker-focused {
      border-color: transparent;
    }

    .custom-input__label {
      z-index: 3;
      visibility: visible;
      color: rgba(34, 59, 111, 0.8);
    }
  }

  .ant-picker.ant-picker-disabled {
    background: $grey-07;
    opacity: 0.8;
    border: 1px solid $grey-06;
    box-sizing: border-box;
    color: $blue-03;
  }

  .month-picker {
    .ant-picker-header {
      display: none;
    }
  }
}
