@import '/src/assets/styles/variable.scss';

.rp-upi-qr-code {
  font-family: 'Neurial Grotesk', sans-serif;

  &__heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    &__left {
      button {
        font-family: 'Neurial Grotesk Bold', sans-serif;
        font-size: 15px;
        color: $grey-02;
        padding-left: 0;
        padding-right: 0;
      }
    }

    &__right {
      font-family: 'Neurial Grotesk Medium', sans-serif;
      font-size: 13px;
      color: $grey-02;

      span {
        margin-left: 4px;
      }
    }
  }

  &__options {
    font-family: 'Neurial Grotesk Bold', sans-serif;
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    .ant-radio {
      top: 7px;

      &.ant-radio-checked {
        .ant-radio-inner {
          border: 6px solid $pink;
        }
      }
    }

    .ant-radio-inner {
      border: 2px solid #B7D2E8;
      box-sizing: border-box;
      border-radius: 12px;
      width: 24px;
      height: 24px;

      &::after {
        display: none;
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
    }

    .ant-radio-wrapper {
      color: $grey-02;
    }

    .divide {
      height: 1px;
      margin-top: 4px;
      margin-bottom: 4px;
      width: 100%;
      background-color: $grey-06;
    }

    .radio-upi-id {
      display: none;

      .ant-row.ant-form-item {
        margin-bottom: 0;
      }

      &.--active {
        display: block;
      }
    }

    .radio-qr-code {
      width: 150px;
      height: 150px;
      display: none;

      &.--active {
        display: flex;
      }
    }
  }
}
