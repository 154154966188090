@import '/src/assets/styles/mixins.scss';
@import '/src/assets/styles/variable.scss';

.rp-paybutton-detail-view-card {
  width: 100%;
  background-color: #FFF;
  box-shadow: 0px 12px 48px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  font-family: 'Neurial Grotesk', sans-serif;
  font-size: 15px;
  letter-spacing: -0.25px;
  overflow: hidden;

  &__wrapper {
    position: relative;
  }

  &__header {
    padding: 32px 64px 48px;
    background-color: $grey-07;
    border-bottom: 1px solid $grey-05;
    position: relative;

    @include iPhone4-portrait {
      padding: 28px 16px 48px;
    }

    @include iPhone4-landscape {
      padding: 28px;
    }

    &__actions {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 1;

      .button-close-x {
        padding: 0;
        height: 20px;
        width: 20px;
        color: rgba(0, 0, 0, 0.45);
      }
    }

    &__logo {
      &-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 48px;

        @include iPhone4-portrait {
          margin-bottom: 30px;
        }

        @include iPhone4-landscape {
          margin-bottom: 30px;
        }

        &__left,
        &__right {
          width: 50%;
        }
      }

      &-item {
        display: flex;
        align-items: center;
        column-gap: 10px;
        height: 40px;

        &.--merchant {
          justify-content: flex-end;

          .merchant-logo {
            height: 32px;
            width: 32px;
            padding: 2px;
            border-radius: 4px;
            border: 1px solid $grey-05;
            box-shadow: 0px 7px 14px 0px rgba(3, 39, 107, 0.1);

            img {
              height: 100%;
              width: 100%;
            }
          }
        }
      }
    }

    &__title {
      font-family: 'Neurial Grotesk Light', sans-serif;
      font-size: 36px;
      letter-spacing: -1px;
      background: linear-gradient(89.09deg, #0849EF -19.31%, #FF0B9A 104.75%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      margin-bottom: 12px;

      @include iPhone4-portrait {
        font-size: 30px;
        line-height: normal;
      }

      @include iPhone4-landscape {
        font-size: 30px;
      }

      &::selection {
        -webkit-text-fill-color: white !important;
      }
    }

    &__desc {
      line-height: 23px;
      letter-spacing: -0.25px;
      color: $blue-03;

      @include iPhone4-portrait {
        font-size: 14px;
      }

      @include iPhone4-landscape {
        font-size: 14px;
      }
    }
  }

  &__main {
    padding: 36px 64px 46px 64px;
    min-height: 30vh;

    @include iPhone4-portrait {
      padding: 32px 16px;
    }

    @include iPhone4-landscape {
      padding: 28px;
    }

    .ant-form-item {
      margin-bottom: 18px;
    }
  }

  &__footer {
    padding: 30px 64px 45px 64px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $grey-05;

    @media (max-width: 576px) {
      padding-left: 16px;
      padding-right: 16px;
      flex-wrap: wrap;
      row-gap: 48px;
      border-top: none;
    }

    &__tac {
      display: flex;
      flex-direction: column;
      width: 292px;

      @media (max-width: 576px) {
        width: 100%;
      }
    }

    &__contact-information {
      display: flex;
      flex-direction: column;
    }

    .title {
      font-family: 'Neurial Grotesk Bold', sans-serif;
      font-size: 15px;
      line-height: 23px;
      color: $grey-02;
      margin-bottom: 16px;
    }

    .desc,
    .info-container {
      font-size: 13px;
      line-height: 19px;
      color: $grey-02;
    }

    .desc {
      white-space: pre-line;
    }
  }
}

.pay-button-close-confirm-container {
  .ant-modal-confirm-title {
    font-family: "Neurial Grotesk Bold", sans-serif;
  }

  .ant-modal-confirm-btns {
    .ant-btn {
      color: $blue-03;
      border-radius: 24px;
      border: none;
      background: $btn-default;
      min-width: 70px;
      box-shadow: none;
      text-shadow: none;

      &.ant-btn-primary {
        color: white;
        background: $btn-gradient;
      }

      &:hover {
        background: $btn-gradient;
        box-shadow: 0px 11px 15px rgba(97, 51, 209, 0.25);

        span {
          color: white;
        }
      }
    }
  }
}
