@import "/src/assets/styles/font.scss";
@import "/src/assets/styles/variable.scss";
@import "/src/assets/styles/message.scss";
@import "/src/assets/styles/common.scss";
@import "/src/assets/styles/custom.scss";

body {
  font-family: 'Neurial Grotesk', sans-serif;

  &.ant-scrolling-effect {
    width: 100% !important;
  }

  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #95BADF #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #95BADF;
    border-radius: 10px;
    // border: 0px double #000000;
  }

  *::-webkit-scrollbar-thumb:horizontal {
    background-color: #95BADF;
    border-radius: 10px;
    // border: 0px double #000000;
  }
}

.user-portal-dashboard {
  min-height: 360px;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.text-white {
  color: white;
}

.fw-lighter {
  font-weight: lighter;
}

.w-100 {
  width: 100%;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mr-6 {
  margin-right: 1.5rem
}

.mt-3 {
  margin-top: 0.75rem;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.m-0 {
  margin: 0;
}

.p-0 {
  padding: 0;
}

.text-right {
  text-align: right;
}

.text-break {
  word-break: break-all;
}

button {
  cursor: pointer;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.loading-spiner {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1000;
}

.fw-bold {
  font-family: "Neurial Grotesk Bold", sans-serif;
  font-weight: bold;
}

*:-webkit-autofill {
  -webkit-background-clip: text !important;
}
