@import "/src/assets/styles/variable.scss";

.rp-phone-number {
  position: relative;
  display: flex;
  column-gap: 12px;
  width: 100%;

  .react-tel-input {
    position: relative;
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;

    &:disabled {
      background: #FAFCFF;
      opacity: 0.8;
      border: 1px solid #E8F3FD;
      box-sizing: border-box;
      color: #050848;
    }

    .special-label,
    .flag-dropdown {
      z-index: 4;
    }

    .flag-dropdown.open {
      z-index: 100;
    }

    &::after {
      content: '';
      border: 1px solid transparent;
      background: $input-focus-gradient border-box;
      -webkit-mask:
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 4px;
      cursor: text;
      z-index: 0;
      min-height: 48px;
      opacity: 0;
      transition: all 0.25s;
    }

    &:focus-within {
      &::after {
        opacity: 1;
        transition: all 0.25s;
      }
    }

    input {
      height: 48px;
      width: 100%;
      padding-top: 16px;
      padding-bottom: 16px;
      padding-left: 65px;
      border-color: $grey-03;
      border-radius: 4px;
      color: rgba(5, 8, 72, 0.8);
      font-family: 'Neurial Grotesk Light', sans-serif;

      &.input-phone {
        padding-top: 12px;
        padding-bottom: 12px;
        z-index: 3;
        transition: all 0.3s, padding-left 0s;

        &:hover {
          border-color: $grey-03;
        }

        &[disabled]:hover {
          border-color: $grey-06;
        }

        &:focus {
          box-shadow: none;
          outline: none;
          border-color: transparent;
          z-index: 1;
          height: calc(100% - 2px);
          width: calc(100% - 2px);
          margin: 0 auto;
          padding-left: 64px;
          transition: all 0.3s;
        }
      }
    }

    .country-list {
      overflow-x: hidden;
    }

    .special-label {
      top: -10px;
      left: 15px;
      color: rgba(34, 59, 111, 0.8);
      font-family: 'Neurial Grotesk', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      user-select: none;
    }

    .selected-flag {
      padding-left: 20px;
    }

    .flag {
      .arrow {
        top: 54%;
      }
    }
  }

  &.--required {
    .special-label::after {
      content: '*';
      color: $red-error;
      margin-left: 1px;
    }
  }
}

.ant-form-item-has-error {
  margin-bottom: 24px;

  input {
    color: $red-error;
  }

  .rp-phone-number {
    .react-tel-input {
      &::after {
        border: none;
      }

      input.input-phone,
      .special-label {
        border-color: $red-error;
        color: $red-error;
      }

      input.input-phone {
        width: 100%;
        height: 100%;
        padding-left: 65px;
      }
    }
  }
}
