.disable-scroll {
  overflow: hidden;
}

.d-block {
  display: block !important;
}

#notify-message__mask {
  position: fixed;
  display: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  transition: 2s all;
}

.ant-form-item-margin-offset {
  margin: 0 !important;
}

.bg-transparent {
  background-color: transparent;
}

.position-relative {
  position: relative;
}
