.payment-pages-not-found {
  &-container {
    height: 100vh;
    position: relative;
    background: linear-gradient(141.98deg, #5138D8 6.99%, #B120B7 89.76%);
  }

  &__sp-image {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 35%;
  }

  &__content {
    color: #FFF;
    font-size: 42px;
    position: absolute;
    top: 30%;
    left: 8%;
    font-family: "Neurial Grotesk Bold", sans-serif;

    @media (max-width: 1024px) {
      font-size: 36px;
    }

    p {
      margin: 0;
    }
  }
}
