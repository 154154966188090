@import '/src/assets/styles/variable.scss';

.rp-paymethod-heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;

  &__left {
    button {
      font-family: 'Neurial Grotesk Bold', sans-serif;
      font-size: 15px;
      color: $grey-02;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__right {
    font-family: 'Neurial Grotesk Medium', sans-serif;
    font-size: 13px;
    color: $grey-02;

    span {
      margin-left: 4px;
    }
  }
}
