@import '/src/assets/styles/mixins.scss';
@import '/src/assets/styles/variable.scss';

.rp__payment-pages__view {
  min-height: 100vh;
  width: 100%;
  &-container {
    background-color: $blue-03;
    position: relative;
    display: flex;
    justify-content: center;
    min-height: 100vh;
  }

  &-slice {
    background-image: url('/assets/images/payment-pages/slice.svg');
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    top: 56px;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
  }

  &-main {
    padding-top: 60px;
    padding-bottom: 60px;
    max-width: 610px;
    width: 100%;
    z-index: 1000;

    @include iPhone4-portrait {
      margin: 0 15px;
      padding: 30px 0;
    }

    @include iPhone4-landscape {
      margin: 0 30px;
      padding: 30px 0;
    }
  }
}
