@import '/src/assets/styles/variable.scss';

body.bg-transparent {
  .rp__payment-buttons__view {
    &-container {
      background-color: transparent;
    }

    &-slice {
      background: none;
    }
  }
}

.rp__payment-buttons__view {
  min-height: 100vh;
  width: 100%;

  &-container {
    background-color: $blue-03;
    position: relative;
    display: flex;
    justify-content: center;
    min-height: 100vh;

    @media (max-width: 576px) {
      background-color: transparent;
    }
  }

  &-slice {
    background-image: url('/assets/images/payment-pages/slice.svg');
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    top: 56px;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;

    @media (max-width: 576px) {
      background-color: white;
      background-image: none;
    }
  }

  &-main {
    padding-top: 60px;
    padding-bottom: 60px;
    max-width: 610px;
    width: 100%;
    z-index: 1000;

    @media (max-width: 576px) {
      padding: 0;
    }
  }
}
