@import '/src/assets/styles/mixins.scss';
@import '/src/assets/styles/variable.scss';

.rp-payment-card {
  &-container {
    border-radius: 8px;
    max-width: 375px;
    overflow: hidden;
    font-family: 'Neurial Grotesk', sans-serif;
    margin: 0 auto;

    @include iPhone4-portrait {
      margin: 0 10px;
    }
  }

  &__heading {
    background-color: $grey-06;
    padding: 32px 16px 19px;
    min-height: 190px;
    position: relative;

    &.--embed-mode {
      padding-top: 42px;
    }

    &__actions {
      position: absolute;
      top: 10px;
      right: 16px;
      left: 16px;
      display: flex;
      height: 20px;
      justify-content: flex-end;

      .button-close-x {
        padding: 0;
        height: 20px;
        width: 20px;
        color: rgba(0, 0, 0, 0.45);
      }
    }

    &__logo {
      &-group {
        display: flex;
        justify-content: space-between;
        padding-bottom: 26px;
        border-bottom: 1px solid $grey-05;
      }

      &-item {
        height: 30px;

        &.--client-merchant {
          display: flex;
          align-items: center;
          column-gap: 10px;

          img {
            width: 30px;
          }

          p {
            font-size: 9px;
          }
        }
      }
    }

    &__payment-info {
      margin-top: 24px;
      text-align: center;
      color: $blue-03;

      &__pay-id {
        font-size: 15px;

        &.--has-back-icon {
          // text-align: left;
          margin-left: -8px;
          margin-right: -8px;
        }

        @include iPhone678-portrait {
          font-size: 14px;
        }

        .button-back {
          padding: 0;
          height: 20px;
          width: 20px;
          margin-right: 5px;
          color: rgba(0, 0, 0, 0.45);

          @include iPhone678-portrait {
            height: 16px;
            width: 16px;

            span {
              font-size: 16px !important;
            }
          }

          &:hover {
            color: #40a9ff;
          }
        }
      }

      &__payment-desc {
        font-family: 'Neurial Grotesk Light', sans-serif;
        font-size: 12px;
        letter-spacing: -0.25px;
      }

      &__payment-amount {
        font-family: 'Neurial Grotesk Light', sans-serif;
        font-size: 32px;
        line-height: 32px;
        letter-spacing: -0.25px;
      }
    }
  }

  &__body {
    padding: 24px 8px;
    min-height: 444px;
    max-height: 568px;
    overflow-y: auto;
    background-color: white;

    &__input {
      &.--custom {
        font-size: 16px;
        font-family: 'Neurial Grotesk Light', sans-serif;
      }

      &.--prefix {
        width: 154px !important;

        &.ant-select-open .ant-select-selection-item {
          .flag-icon {
            display: none;
          }
        }
      }

      &__email {
        padding-left: 8px;
        padding-right: 8px;
      }

      &__phone {
        display: flex;
        column-gap: 11px;
        width: 100%;
        padding-left: 8px;
        padding-right: 8px;
      }
    }

    &__choose-pay-method-text {
      margin-bottom: 16px;
      font-size: 17px;
      line-height: 27px;
      letter-spacing: -0.25px;
      color: $grey-02;
    }

    &__fail {
      &-container {
        margin-top: 64px;
      }

      &-icon {
        height: 76px;
        width: 76px;
        display: flex;
        margin: 0 auto;
      }

      &__content {
        color: #C62937;
        font-family: 'Neurial Grotesk Bold', sans-serif;
        font-weight: 700;
        font-size: 17px;
        text-align: center;
        letter-spacing: -0.25px;
        margin-top: 20px;

        p {
          margin-bottom: 32px;
        }
      }
    }

    &__proceeding {
      margin-top: 129px;
      font-family: 'Neurial Grotesk Bold', sans-serif;
      font-size: 17px;
      line-height: 27px;
      letter-spacing: -0.25px;
      color: #000000;
      text-align: center;
    }

    &__upi-process {
      overflow-x: hidden;

      .divide {
        height: 1px;
        width: calc(100% + 32px);
        margin-left: -16px;
        background-color: $grey-06;
      }

      &__acount-verified {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        column-gap: 6px;
        padding-bottom: 36px;
        padding-top: 12px;

        .fill-checked-icon {
          display: flex;
          width: 24px;
          height: 24px;
        }

        .label {
          font-family: 'Neurial Grotesk Bold', sans-serif;
          font-size: 15px;
          text-align: center;
          color: $blue-03;
        }

        .info {
          font-family: 'Neurial Grotesk', sans-serif;
          font-size: 15px;
          text-align: center;
          color: $blue-03;
        }
      }

      &__timecounter-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        column-gap: 14px;
        margin-top: 16px;

        .upi-logo {
          display: flex;
          height: 30px;
          margin: 34px 32px;
        }

        .desc {
          font-family: 'Neurial Grotesk', sans-serif;
          font-size: 17px;
          letter-spacing: -0.25px;
          color: #223B6F;
          text-align: center;
        }

        .counter-container {
          display: flex;
          justify-content: center;
          align-items: baseline;

          .time-counter {
            font-family: 'Neurial Grotesk Light', sans-serif;
            font-size: 36px;
            line-height: 44px;
            text-align: center;
            letter-spacing: -0.75px;
            background: linear-gradient(89.09deg, #0849EF -19.31%, #FF0B9A 104.75%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
          }

          .time-unit {
            font-family: 'Neurial Grotesk Bold', sans-serif;
            font-size: 15px;
            line-height: 44px;
            color: $blue-02;
            margin-left: 6px;
          }
        }
      }
    }
  }

  &__footer {
    min-height: 103px;
    padding-top: 28px;
    padding-bottom: 28px;
    background-color: white;
    filter: drop-shadow(-20px 0px 29px rgba(183, 31, 181, 0.19));
  }
}

.rp-payment-methods {
  label {
    display: contents;

    &:last-child {
      .rp-payment-methods__item-option {
        margin-bottom: 0;
      }
    }

    &.ant-radio-button-wrapper-disabled {
      .rp-payment-methods {
        &__item-option {
          cursor: not-allowed;
          color: rgba(0, 0, 0, 0.25);
          background-color: #f5f5f5;

          &:hover,
          &:focus {
            background-color: #f5f5f5;

            .rp-payment-methods__item-option__icon {
              &.--active {
                visibility: hidden;
              }
            }

            .rp-payment-methods__item-option__title {
              background: unset;
              -webkit-background-clip: unset;
              -webkit-text-fill-color: unset;
              background-clip: unset;
            }
          }
        }
      }
    }
  }

  &__item-option {
    padding: 16px 8px;
    border: 1px solid $grey-05;
    border-radius: 4px;
    margin-bottom: 16px;

    &:hover,
    &:focus {
      cursor: pointer;

      .rp-payment-methods__item-option__icon {
        &.--active {
          visibility: visible;
        }
      }

      .rp-payment-methods__item-option__title {
        background: linear-gradient(141.98deg, #5138D8 6.99%, #B120B7 89.76%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }

    &__describe {
      display: flex;
      align-items: center;
      column-gap: 12px;
      position: relative;
      font-family: 'Neurial Grotesk', sans-serif;
    }

    &__icon {
      position: relative;
      min-width: 40px;
      text-align: center;

      &.--active {
        position: absolute;
        visibility: hidden;
      }
    }


    &__title {
      font-family: 'Neurial Grotesk Bold', sans-serif;
      font-size: 17px;
      line-height: 27px;
      letter-spacing: -0.25px;
      color: $grey-02;
    }

    &__desc {
      font-family: 'Neurial Grotesk', sans-serif;
      font-size: 15px;
      line-height: 23px;
      letter-spacing: 0px;
      color: $grey-02;
    }
  }

}

.rp-payment-mask{
  width: 100%;
  position: relative;

  &>.proceeding-mask {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(250, 250, 250, 0.7);
    display: none;
    z-index: 4;
  }

  &.--proceeding {
    &>.proceeding-mask {
      display: block;
    }
  }
}
