.payment-pages-deactivated {
  &-container {
    height: 100vh;
    position: relative;
    background: linear-gradient(141.98deg, #5138D8 6.99%, #B120B7 89.76%);

    p {
      margin: 0;
    }
  }

  &__sp-image {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 35%;
  }

  &__content {
    position: absolute;
    top: 30%;
    left: 8%;
    color: #FFF;
    font-family: "Neurial Grotesk Light", sans-serif;

    &-title {
      font-size: 48px;
      font-family: "Neurial Grotesk Bold", sans-serif;

      @media (max-width: 1024px) {
        font-size: 36px;
      }
    }
  }

  &__contact {
    &-merchant {
      font-size: 20px;
      margin-top: 20px;

      @media (max-width: 1024px) {
        font-size: 16px;
      }
    }

    &-info  {
      display: flex;
      column-gap: 50px;

      &__item {
        display: flex;
        column-gap: 8px;
        align-items: center;
      }
    }
  }
}
