@import "/src/assets/styles/variable.scss";

.rp-input-number-container {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .custom-input__label {
    z-index: 4;
  }

  &.disabled-input {
    background-color: #f5f5f5;

    .ant-input-number-prefix,
    .ant-input-number {
      color: rgba(5, 8, 72, 0.8);
    }

    .custom-input__label {
      background-color: #f5f5f5;
    }
  }

  .ant-input-number-affix-wrapper {
    min-width: 100%;
    padding: 0 16px !important;
    border: 1px solid #B7D2E8;
    border-radius: 4px !important;
    width: 100%;
    height: 48px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    font-family: 'Neurial Grotesk Light', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: rgba(5, 8, 72, 0.8);
    outline: none;
    box-shadow: none;
    transition: all 0s;

    &:hover {
      border-color: #B7D2E8;
    }

    &:not(.ant-input-number-affix-wrapper-status-error):focus-within {
      box-shadow: none;
      outline: none;
      position: relative;
      border-color: transparent;
      z-index: 1;
      padding: 0 16px !important;

      &~.hover-style {
        opacity: 1;
        top: -1px;
        bottom: -1px;
        left: -1px;
        right: -1px;
      }

      &.--has-value {
        border-color: transparent;
      }
    }

    input {
      font-size: 16px;
      border: none !important;
      height: 45px;
      padding-left: 0 !important;
      z-index: 1;

      &.ant-input-number-disabled {
        border: none;

        &[disabled]:hover {
          // border-color: $grey-06;
        }
      }
    }

    &.--has-value {
      background-color: transparent;
      border: 1px solid $grey-03;

      // &.--disabled {
      //   background: transparent;
      // }

      &~.custom-input__label {
        visibility: visible;
        z-index: 3;
        color: rgba(34, 59, 111, 0.8);
      }
    }

    // .--disabled {
    //   background: $grey-07;
    //   // border: 1px solid $grey-06;
    //   box-sizing: border-box;
    //   color: $blue-03;

    //   &[disabled]:hover {
    //     border-color: $grey-06;
    //   }
    // }

    &.ant-input-number-affix-wrapper-status-error {
      box-shadow: none !important;
      z-index: 3 !important;

      input,
      .ant-input-number-prefix {
        color: $red-error;
      }

      &.--has-value {
        &~.custom-input__label {
          color: $red-error;
        }
      }
    }
  }

  .ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
  .ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
    border-color: $red-error;
  }
}
