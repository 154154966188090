// Breakpoints refer this https://ui.dev/rwd/develop/browser-feature-support/media-queries-for-common-device-breakpoints
/* Smartphones (portrait and landscape) ----------- */
@mixin smartphones-portrait-and-landscape {
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    @content;
  }
}

/* Smartphones (landscape) ----------- */
@mixin smartphones-landscape {
  @media only screen and (min-width: 321px) {
    @content;
  }
}

/* Smartphones (portrait) ----------- */
@mixin smartphones-portrait {
  @media only screen and (max-width: 320px) {
    @content;
  }
}

/* iPads (portrait and landscape) ----------- */
@mixin ipads-portrait-and-landscape {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    @content;
  }
}

/* iPads (landscape) ----------- */
@mixin ipads-landscape {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    @content;
  }
}

/* iPads (portrait) ----------- */
@mixin ipads-portrait {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    @content;
  }
}

/* iPad 3 (landscape) ----------- */
@mixin ipad3-landscape {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}

/* iPad 3 (portrait) ----------- */
@mixin ipad3-portrait {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}

/* iPad Pro (landscape) ----------- */
@mixin ipad-pro-landscape {
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}

/* iPad Pro (portrait) ----------- */
@mixin ipad-pro-portrait {
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}

/* Desktops and laptops ----------- */
@mixin desktops-and-laptops {
  @media only screen and (min-width: 1224px) {
    @content;
  }
}

/* Large screens ----------- */
@mixin large-screens {
  @media only screen and (min-width: 1824px) {
    @content;
  }
}

/* iPhone 4 ----------- */
@mixin iPhone4-landscape {
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}

@mixin iPhone4-portrait {
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}

/* iPhone 6, 7, 8 ----------- */
@mixin iPhone678-landscape {
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
    @content;
  }
}

@mixin iPhone678-portrait {
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    @content;
  }
}

/* iPhone 6+, 7+, 8+ ----------- */
@mixin iPhone678-plus-landscape {
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
    @content;
  }
}

@mixin iPhone678-plus-portrait {
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    @content;
  }
}

/* iPhone X ----------- */
@mixin iPhoneX-landscape {
  @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
    @content;
  }
}

@mixin iPhoneX-portrait {
  @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
    @content;
  }
}

/* iPhone XS Max, XR ----------- */
@mixin iPhoneXSMax-XR-landscape {
  @media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
    @content;
  }
}

@mixin iPhoneXSMax-XR-portrait {
  @media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
    @content;
  }
}

/* Samsung Galaxy S3 ----------- */
@mixin samsung-s3-landscape {
  @media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
    @content;
  }
}

@mixin samsung-s3-portrait {
  @media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    @content;
  }
}

/* Samsung Galaxy S4 ----------- */
@mixin samsung-s4-landscape {
  @media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
    @content;
  }
}

@mixin samsung-s4-portrait {
  @media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
    @content;
  }
}

/* Samsung Galaxy S5 ----------- */
@mixin samsung-s5-landscape {
  @media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
    @content;
  }
}

@mixin samsung-s5-portrait {
  @media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
    @content;
  }
}
