@import '/src/assets/styles/variable.scss';

.rdcp-payment-processing {
  min-height: 100vh;
  width: 100%;

  &-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    background-color: $blue-03;

    .header {
      height: 80px;
      border-bottom: 1px solid rgba(232, 243, 253, 0.3);
      display: flex;
      padding: 0 15px;
      align-items: center;

      &__logo {
        height: 40px;
      }
    }

    .content {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      .card {
        &-container {
          position: relative;
          height: 280px;
          width: 360px;
          background-color: $grey-color;
          border-radius: 8px;
          overflow: hidden;
        }

        &-body {
          padding: 0 15px;
          text-align: center;
          height: calc(100% - 50px);
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          .title {
            font-family: 'Neurial Grotesk Bold', sans-serif;
            font-size: 18px;
          }

          .subtitle {
            font-family: 'Neurial Grotesk Light', sans-serif;
            font-size: 14px;
          }

          .loading-icon {
            margin-top: 10%;
          }
        }

        &-footer {
          height: 50px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: 'Neurial Grotesk', sans-serif;
          background-color: $grey-06;
          font-size: 12px;
          column-gap: 8px;

          .footer-logo {
            height: 24px;
          }
        }
      }
    }

    .footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 40px;
      color: #FFF;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 1px solid rgba(232, 243, 253, 0.3);
    }
  }

  &-slice {
    background-image: url('/assets/images/payment-pages/slice.svg');
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    top: 56px;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
  }
}
