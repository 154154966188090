@import '/src/assets/styles/variable.scss';

.rp-credit-debit-card {
  width: 100%;
  position: relative;

  &__heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    &__left {
      button {
        font-family: 'Neurial Grotesk Bold', sans-serif;
        font-size: 15px;
        color: $grey-02;
        padding-left: 0;
        padding-right: 0;
      }
    }

    &__right {
      font-family: 'Neurial Grotesk Medium', sans-serif;
      font-size: 13px;
      color: $grey-02;

      span {
        margin-left: 4px;
      }
    }
  }

  &__cards {
    display: flex;
    column-gap: 8px;
    margin-top: 16px;
    margin-bottom: 24px;

    &-item {
      width: 34px;
      height: 24px;
    }
  }

  &__form {
    &__input-inline {
      display: flex;
      justify-content: center;
      column-gap: 16px;

      .ant-picker-input {
        input {
          text-align: center;
        }
      }
    }
  }
}
